
// No used but keeping it for later use for now
const isServer = typeof window === 'undefined'
const isPreview = () => {
	let isPreviewValue = isServer
		? process.env.STORYBLOK_IS_PREVIEW
		: window.ENV.STORYBLOK_IS_PREVIEW

	return isPreviewValue === 'yes'
}
export { isPreview }
